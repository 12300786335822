// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Item } from '~/common/gql';
import { getItemTitle } from '~/common/utilities/item-utils';
import { ItemContentType } from '~/containers/common/Item/ItemContentType';

export type TItemContentOnly = Pick<Item, 'urlData' | 'title' | 'url' | 'note'>;

export const getItemContent = (item: TItemContentOnly): ItemContentType => ({
  imageSrc: item.urlData?.imageSrc,
  title: getItemTitle(item),
  description: item.urlData?.description,
  note: item.note,
  url: item.url,
});
