// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Button, forwardRef, StackProps } from '@chakra-ui/react';
import Image from 'next/image';
import Illustration from '~/images/illustration-couldnt-load.png';
import { OopsMessage } from './OopsMessage';

export const ProblemsFetchingBookmarksOopsMessage = forwardRef<
  StackProps,
  'div'
>((props, ref) => (
  <OopsMessage
    ref={ref}
    image={<Image src={Illustration} width={237} height={178} alt="" />}
    heading="Oops, we had a problem loading your items."
    description="Don’t worry – your items are saved. Try loading the page again."
    {...props}
  >
    <Button variant="npPrimary" onClick={() => location.reload()}>
      Try again
    </Button>
  </OopsMessage>
));
