// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { createContext } from 'react';

interface Props {
  getIsFeatureEnabled: (featureId: string) => boolean | undefined;
}

export const FeatureContext = createContext<Props>({
  getIsFeatureEnabled: () => undefined,
});
