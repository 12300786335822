// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import { useUserPreferenceQuery } from '~/common/gql/user.generated';
import { useOnboardingHintBase } from '~/containers/common/OnboardingHint/useOnboardingHintBase';

export const useOnboardingAddBookmarkHint = () => {
  const { isOpen, onOpen, onClose } = useOnboardingHintBase(
    'hideAddBookmarkHint',
  );
  const { data, loading, error, called } = useUserPreferenceQuery();

  useEffect(() => {
    if (!loading && called && !error) {
      // hide onboarding hints until we receive the user's preferences
      const isWelcomeMessageHidden =
        data?.userPreference?.hideWelcomeMessage ?? false; // assume hidden unless disproven
      const isPreferenceSet = !isNil(data?.userPreference?.hideAddBookmarkHint);
      const isNotHiddenRemotely =
        data?.userPreference?.hideAddBookmarkHint === false;

      if (isWelcomeMessageHidden && isPreferenceSet && isNotHiddenRemotely) {
        // only show hint if the welcome message is hidden
        // and is not set to hidden remotely
        onOpen();
        return;
      }
    }
  }, [
    called,
    data?.userPreference?.hideAddBookmarkHint,
    data?.userPreference?.hideWelcomeMessage,
    error,
    loading,
    onOpen,
  ]);

  return {
    isOpen,
    onClose,
  };
};
