// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
} from '@chakra-ui/react';
import { NpNote } from '~/components/Icons/NpNote';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  isNoteCreated: boolean;
}

export const AddNoteIconButton = forwardRef<Props, 'div'>(
  ({ isNoteCreated, ...props }, ref) => {
    const label = isNoteCreated ? 'Edit note' : 'Create note';
    return (
      <Tooltip label={label} placement="top">
        <IconButton
          ref={ref}
          variant="npGhost"
          icon={<ReactIcon as={NpNote} />}
          aria-label={label.toString()}
          {...props}
        />
      </Tooltip>
    );
  },
);
