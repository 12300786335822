// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { useContext } from 'react';
import { FeatureContext } from './FeatureContext';

export const useFeatureContext = () => {
  const props = useContext(FeatureContext);

  return props;
};
