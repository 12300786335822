// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Card,
  CardProps,
  forwardRef,
  SystemStyleObject,
} from '@chakra-ui/react';
import { onlyVisibleOnWideCardHoverClassName } from './constants';

export const WideCard = forwardRef<CardProps, 'div'>((props, ref) => {
  // only show card-hover-box when card is hovered
  const _noHover = `& .${onlyVisibleOnWideCardHoverClassName}`;
  const _hover = `&:hover .${onlyVisibleOnWideCardHoverClassName}`;
  const sx: SystemStyleObject = {
    ...props.sx,
    [_noHover]: {
      visibility: ['visible', 'visible', 'hidden'],
      ...props.sx?.[_noHover],
    },
    [_hover]: {
      visibility: 'visible',
      ...props.sx?.[_hover],
    },
  };
  return (
    <Card ref={ref} size="sm" variant="npListCard" {...props} sx={sx}></Card>
  );
});
