// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text, TextProps } from '@chakra-ui/react';

export const TileUrl = forwardRef<TextProps, 'div'>((props, ref) => {
  return (
    <Text
      ref={ref}
      color="npSubduedText"
      fontSize="xs"
      fontWeight="normal"
      noOfLines={1}
      data-dd-privacy="mask"
      {...props}
    />
  );
});
