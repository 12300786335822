// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';

export const BreadcrumbHeading = forwardRef<HeadingProps, 'h2'>(
  ({ children, ...props }, ref) => {
    return (
      <Heading ref={ref} variant="npTitle" noOfLines={1} {...props}>
        {children}
      </Heading>
    );
  },
);
