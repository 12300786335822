// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Card,
  CardBody,
  CardProps,
  Collapse,
  forwardRef,
} from '@chakra-ui/react';
import debounce from 'lodash/debounce';
import { useEffect, useMemo, useState } from 'react';

export interface HintCollapseProps extends CardProps {
  expand: boolean;
  setIsHoveringHint: (boolean) => void;
}

export const HintCollapse = forwardRef<HintCollapseProps, 'div'>(
  ({ expand, setIsHoveringHint, children, ...props }, ref) => {
    const [_expand, _setExpand] = useState(expand);

    const setExpand = useMemo(
      () =>
        debounce((expand) => {
          _setExpand(expand);
        }, 100),
      [_setExpand],
    );

    useEffect(() => {
      // required to prevent expand state jitter on mobile
      // while isHoveringHint state is updated
      setExpand(expand);
      // TODO: Fix warning appropriately then remove the line below.
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [expand]);

    return (
      <Collapse in={_expand} animateOpacity>
        <Card
          ref={ref}
          variant="npAccent"
          {...props}
          onMouseEnter={(e) => {
            setIsHoveringHint(true);
            props.onMouseEnter?.(e);
          }}
          onMouseLeave={(e) => {
            setTimeout(() => setIsHoveringHint(false), 2000);
            props.onMouseLeave?.(e);
          }}
        >
          <CardBody>{children}</CardBody>
        </Card>
      </Collapse>
    );
  },
);
