// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { MdMenu } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { PreviewText } from '~/components/PreviewText/PreviewText';
import { useAppContext } from '~/containers/common/AppContext';

interface Props extends ButtonProps {}

export const LeftSidebarDrawerOpenButton = forwardRef<Props, 'button'>(
  ({ children, ...props }, ref) => {
    const { mainMenuDrawer } = useAppContext();
    return (
      <Button
        ref={ref}
        pl={1}
        variant="npGhost"
        leftIcon={<ReactIcon as={MdMenu} size="sm" />}
        onClick={mainMenuDrawer.onOpen}
        {...props}
      >
        <PreviewText>{children}</PreviewText>
      </Button>
    );
  },
);
