// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, MenuGroup, MenuGroupProps } from '@chakra-ui/react';
import { LayoutType } from '~/common/gql';
import { NpList } from '~/components/Icons/NpList';
import { NpTile } from '~/components/Icons/NpTile';
import { MenuItemSelectable } from './MenuItemSelectable';

interface Props extends MenuGroupProps {
  layout: LayoutType;
  setLayout: (collectionLayout: LayoutType) => void;
}

export const CollectionLayoutMenuGroup = forwardRef<Props, 'button'>(
  ({ layout, setLayout, ...props }, ref) => {
    return (
      <MenuGroup title="View mode" {...props}>
        <MenuItemSelectable
          selected={layout === LayoutType.Tile}
          onClick={() => setLayout(LayoutType.Tile)}
        >
          <NpTile size="sm" />
          Tile view
        </MenuItemSelectable>
        <MenuItemSelectable
          selected={layout === LayoutType.List}
          onClick={() => setLayout(LayoutType.List)}
        >
          <NpList size="sm" />
          List view
        </MenuItemSelectable>
      </MenuGroup>
    );
  },
);
