// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Flex,
  FlexProps,
  forwardRef,
  useColorModeValue,
} from '@chakra-ui/react';

export const TileHoverIndicator = forwardRef<FlexProps, 'div'>((props, ref) => {
  const cardHover = useColorModeValue('npDust.400', 'npBurple.650');
  return (
    <Flex
      ref={ref}
      gap={2}
      direction="column"
      cursor="pointer"
      _hover={{ bg: cardHover }}
      {...props}
    />
  );
});
