// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { CardHeader, CardHeaderProps, forwardRef } from '@chakra-ui/react';

export const TileCardHeader = forwardRef<CardHeaderProps, 'div'>(
  ({ ...props }, ref) => {
    return (
      <CardHeader
        display="flex"
        direction="row"
        alignItems="center"
        ref={ref}
        {...props}
      />
    );
  },
);
