// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Link } from '@chakra-ui/react';
import { chromeExtUrl } from '~/common/links';
import {
  HintCollapse,
  HintCollapseProps,
} from '~/components/HintCollapse/HintCollapse';

export const InstallBrowserExtHintAd = forwardRef<HintCollapseProps, 'div'>(
  (props, ref) => {
    return (
      <HintCollapse ref={ref} {...props}>
        Psst, you can save bookmarks faster with our&nbsp;
        <Link variant="npAccent" href={chromeExtUrl} isExternal>
          Chrome extension
        </Link>
        !
      </HintCollapse>
    );
  },
);
