// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Heading, HeadingProps } from '@chakra-ui/react';

export const TileTitle = forwardRef<HeadingProps, 'div'>((props, ref) => {
  return (
    <Heading
      ref={ref}
      data-dd-privacy="mask"
      fontSize="md"
      wordBreak="break-word"
      {...props}
    />
  );
});
