// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Card,
  CardProps,
  forwardRef,
  SystemStyleObject,
} from '@chakra-ui/react';
import { onlyVisibleOnTileCardHoverClassName } from './constants';

export const TileCard = forwardRef<CardProps, 'div'>((props, ref) => {
  // only show card-hover-box when card is hovered
  const _selectPlain = `& .${onlyVisibleOnTileCardHoverClassName}`;
  const _selectHover = `&:hover .${onlyVisibleOnTileCardHoverClassName}`;
  const sx: SystemStyleObject = {
    ...props.sx,
    [_selectPlain]: {
      visibility: ['visible', 'visible', 'hidden'],
      ...props.sx?.[_selectPlain],
    },
    [_selectHover]: {
      visibility: 'visible',
      ...props.sx?.[_selectHover],
    },
  };
  return (
    <Card
      ref={ref}
      variant="npSolid"
      size="sm"
      height="100%"
      boxShadow="md"
      outline="none"
      {...props}
      sx={sx}
    />
  );
});
