// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Box, Link, MenuButton, Spacer, useDisclosure } from '@chakra-ui/react';
import first from 'lodash/first';
import NextLink from 'next/link';
import { FC, SyntheticEvent } from 'react';
import { TodoItem } from '~/common/gql';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import { getIsNoteCreated } from '~/common/utilities/item-utils/getIsNoteCreated';
import { getItemContent } from '~/common/utilities/item-utils/getItemContent';
import {
  getIsNoteExists,
  getIsTodo,
  getIsTodoDone,
} from '~/common/utilities/item-utils/item';
import { getBaseUrl } from '~/common/utilities/url-utils/getBaseUrl';
import { getUrlAbbreviation } from '~/common/utilities/url-utils/getUrlAbbreviation';
import { getUrlColor } from '~/common/utilities/url-utils/getUrlColor';
import { ActionType } from '~/common/utilities/usage-event/actionType';
import { IfWrap } from '~/components/IfWrap/IfWrap';
import { TagBlock } from '~/components/TagBlock/TagBlock';
import { useTagBlock } from '~/components/TagBlock/useTagBlock';
import { getEnhancedCollections } from '~/containers/common/Collection/getEnhancedCollection';
import { ModalCollectionPicker } from '~/containers/common/Collection/ModalCollectionPicker/ModalCollectionPicker';
import { useModalCollectionPickerForItem } from '~/containers/common/Collection/ModalCollectionPicker/useModalCollectionPickerForItem';
import { AddNoteIconButton } from '~/containers/common/Item/AddNoteIconButton';
import { ItemMenu } from '~/containers/common/Item/ItemMenu/ItemMenu';
import { ModalItemDelete } from '~/containers/common/Item/ModalItemDelete/ModalItemDelete';
import { ModalItemNoteDelete } from '~/containers/common/Item/ModalItemNoteDelete/ModalItemNoteDelete';
import { ModalItemNoteEdit } from '~/containers/common/Item/ModalItemNoteEdit/ModalItemNoteEdit';
import { ModalItemTitleRename } from '~/containers/common/Item/ModalItemTitleRename/ModalItemTitleRename';
import { MoreIconButton } from '~/containers/common/Item/MoreIconButton';
import { OpenOriginalIconButton } from '~/containers/common/Item/OpenOriginalIconButton';
import { TodoIconButton } from '~/containers/common/Item/TodoIconButton';
import { ToggleTodoIconButton } from '~/containers/common/Item/ToggleTodoIconButton';
import { TPrivateItemCard } from '~/containers/common/Item/TPrivateItemCard';
import { useItemTags } from '~/containers/common/Item/useItemTags';
import { OnboardingCardTodoMarkDoneHint } from '~/containers/common/OnboardingHint/OnboardingCardTodoMarkDoneHint';
import { TileCard } from '~/containers/common/TileCard/TileCard';
import { TileCardBody } from '~/containers/common/TileCard/TileCardBody';
import { TileCardHeader } from '~/containers/common/TileCard/TileCardHeader';
import { TileHoverIndicator } from '~/containers/common/TileCard/TileHoverIndicator';
import { TileImage } from '~/containers/common/TileCard/TileImage';
import { TileRevealOnHover } from '~/containers/common/TileCard/TileRevealOnHover';
import { getEnhancedTodoItem } from '~/containers/common/Todo/getEnhancedTodoItem';
import { TodoUpdatedToast } from '~/containers/common/Todo/TodoUpdatedToast';
import { getItemPath } from '~/containers/pages/ReaderPage/helpers';
import { useCollectionsQueryHelper } from '~/hooks/useCollectionsQueryHelper';
import { useDisclosureWithTracking } from '~/hooks/useDisclosureWithTracking';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useUsageEventCreateMutationHelper } from '~/hooks/useUsageEventCreateMutationHelper';
import { TileDescription } from '../TileCard/TileDescription';
import { TileNote } from '../TileCard/TileNote';
import { TileTitle } from '../TileCard/TileTitle';
import { TileUrl } from '../TileCard/TileUrl';

export const ItemTileCard: FC<TPrivateItemCard> = ({
  item,
  showCardTodoMarkDoneHint,
  showCardManageTagsHint,
}) => {
  const todo = first(item.todo);
  const isDone = getIsTodoDone(item);
  const isNoteCreated = getIsNoteCreated(item);
  const isNoteExists = getIsNoteExists(item);
  const itemContent = getItemContent(item);
  const baseUrl = getBaseUrl(itemContent.url);
  const fallbackHeading = getUrlAbbreviation(itemContent.url);
  const fallbackBg = getUrlColor(itemContent.url);

  const { renderToastWith } = useToastHelper();
  const sectionsQuery = useListSectionsQuery();
  const { userCollections } = useCollectionsQueryHelper();
  const { logUsageEventWithAttributes } = useUsageEventCreateMutationHelper();
  const modalItemTitleRename = useDisclosure();
  const modalItemNoteEdit = useDisclosureWithTracking({
    openAction: ActionType.OPEN_NOTE_EDITOR,
  });
  const modalItemNoteDelete = useDisclosure();
  const modalItemDelete = useDisclosure();

  const enhancedTodo = getEnhancedTodoItem(item.todo);
  const enhancedCollections = getEnhancedCollections({
    all: userCollections,
    selected: item.collection,
  });

  const tagBlock = useTagBlock({
    item: {
      id: item.id,
    },
    tags: enhancedCollections.filter((it) => it.selected),
    showCardManageTagsHint,
  });

  const {
    createTodo,
    removeTodo,
    setTodoDone: _setTodoDone,
  } = useItemTags({
    item: { id: item.id },
  });

  const tagPickerPopupProps = useModalCollectionPickerForItem({
    item: {
      id: item.id,
    },
    sections: sectionsQuery.data?.listSections ?? [],
    enhancedCollections: enhancedCollections,
  });

  const setTodoDone = (todoId: TodoItem['id'], isDone: boolean) => {
    renderToastWith({
      message: (
        <TodoUpdatedToast
          isDone={isDone}
          onClick={() => {
            _setTodoDone(todoId, !isDone);
          }}
        />
      ),
    })();
    _setTodoDone(todoId, isDone);
  };

  const onClickItemURL = (event: SyntheticEvent) => {
    logUsageEventWithAttributes(event, {
      actionType: ActionType.CLICK_ITEM_URL,
      itemId: item?.id,
    });
  };

  return (
    <Box data-dd-privacy="mask">
      {item && (
        <ModalItemTitleRename
          item={item}
          isOpen={modalItemTitleRename.isOpen}
          onClose={modalItemTitleRename.onClose}
        />
      )}
      {item && (
        <ModalItemNoteEdit
          item={item}
          isOpen={modalItemNoteEdit.isOpen}
          onClose={modalItemNoteEdit.onClose}
          onDelete={modalItemNoteDelete.onOpen}
        />
      )}
      {item && (
        <ModalItemNoteDelete
          item={item}
          isOpen={modalItemNoteDelete.isOpen}
          onClose={modalItemNoteDelete.onClose}
        />
      )}
      {item && (
        <ModalItemDelete
          item={item}
          isOpen={modalItemDelete.isOpen}
          onClose={modalItemDelete.onClose}
        />
      )}
      {item && <ModalCollectionPicker {...tagPickerPopupProps} />}
      <TileCard data-item-tile-card>
        <TileCardHeader>
          {enhancedTodo.selected && (
            <IfWrap
              condition={showCardTodoMarkDoneHint}
              wrapWith={(children) => (
                <OnboardingCardTodoMarkDoneHint>
                  {children}
                </OnboardingCardTodoMarkDoneHint>
              )}
            >
              <TodoIconButton
                size="xs"
                isDone={enhancedTodo.isDone}
                onClick={() => setTodoDone(todo.id, !isDone)}
              />
            </IfWrap>
          )}
          <Spacer />
          <TileRevealOnHover>
            <Link href={item.url} onClick={onClickItemURL} isExternal>
              <OpenOriginalIconButton />
            </Link>
            <AddNoteIconButton
              isNoteCreated={isNoteCreated}
              onClick={modalItemNoteEdit.onOpen}
            />
            <ToggleTodoIconButton
              isTodo={enhancedTodo.selected}
              onClick={
                enhancedTodo.selected
                  ? () => removeTodo(enhancedTodo.id)
                  : createTodo
              }
            />
          </TileRevealOnHover>
          <ItemMenu
            item={item}
            onEditItemCollections={tagPickerPopupProps.popup.onOpen}
            onRenameTitle={modalItemTitleRename.onOpen}
            onEditNote={modalItemNoteEdit.onOpen}
            onDeleteNote={modalItemNoteDelete.onOpen}
            onDeleteItem={modalItemDelete.onOpen}
          >
            <MenuButton as={MoreIconButton} aria-label="Open item menu" />
          </ItemMenu>
        </TileCardHeader>
        <TileHoverIndicator
          onClick={(event) => {
            if (isNoteExists) {
              // only log event if item has a note
              logUsageEventWithAttributes(
                event,
                {
                  actionType: ActionType.OPEN_ITEM_WITH_NOTE,
                },
                ['text'],
              );
            }
            if (getIsTodo(item)) {
              // only log event if item has a to do
              logUsageEventWithAttributes(
                event,
                {
                  actionType: ActionType.OPEN_ITEM_WITH_TODO,
                },
                ['text'],
              );
            }
          }}
        >
          <NextLink href={getItemPath({ id: item.id })}>
            <TileImage
              title={itemContent.title}
              imageSrc={itemContent.imageSrc}
              fallbackHeading={fallbackHeading}
              fallbackBg={fallbackBg}
            />
            <TileCardBody pb={1.5}>
              {itemContent.title && <TileTitle>{itemContent.title}</TileTitle>}
              <TileUrl>{baseUrl}</TileUrl>
              {!itemContent.note && itemContent.description && (
                <TileDescription>{itemContent.description}</TileDescription>
              )}
            </TileCardBody>
          </NextLink>
        </TileHoverIndicator>
        {itemContent.note && (
          <TileCardBody py={1.5}>
            <TileNote onClick={modalItemNoteEdit.onOpen}>
              {itemContent.note}
            </TileNote>
          </TileCardBody>
        )}
        <TileCardBody pt={1.5}>
          <TagBlock
            active={tagPickerPopupProps.popup.isOpen}
            onClickTagAdd={tagPickerPopupProps.popup.onOpen}
            {...tagBlock}
          />
        </TileCardBody>
      </TileCard>
    </Box>
  );
};
