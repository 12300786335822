// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import { Item } from '~/common/gql';
import { useItemTags } from '~/containers/common/Item/useItemTags';
import { TagBlockProps } from './TagBlock';
import { TagBlockItemProps } from './TagBlockItemProps';

interface Props {
  item: Pick<Item, 'id'>;
  tags: TagBlockItemProps[];
  showCardManageTagsHint?: boolean;
}

type ReturnType = Omit<TagBlockProps, 'children' | 'active'>;

export const useTagBlock = ({
  item,
  tags,
  showCardManageTagsHint,
}: Props): ReturnType => {
  const { removeTag } = useItemTags({
    item: { id: item.id },
  });

  return {
    tags,
    onCloseTag: (tag) => removeTag(tag.id),
    showCardManageTagsHint,
  };
};
