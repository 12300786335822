// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, MenuButton, MenuButtonProps } from '@chakra-ui/react';
import { MdArrowDropDown } from 'react-icons/md';
import { LayoutType } from '~/common/gql';
import { BlowfishButton } from '~/components/BlowfishButton';
import { ReactIcon } from '~/components/Icons';
import { NpList } from '~/components/Icons/NpList';
import { NpTile } from '~/components/Icons/NpTile';

const tileTitle = 'Tile view';
const listTitle = 'List view';

interface Props extends MenuButtonProps {
  layout: LayoutType;
}

export const CollectionLayoutMenuButton = forwardRef<Props, 'button'>(
  ({ layout, ...props }, ref) => {
    const isTileLayout = layout === LayoutType.Tile;
    const isListLayout = layout === LayoutType.List;
    const title = isTileLayout
      ? tileTitle
      : isListLayout
        ? listTitle
        : undefined;

    return (
      <BlowfishButton
        ref={ref}
        as={MenuButton}
        size="sm"
        variant="npGhostDust"
        leftIcon={isTileLayout ? <NpTile size="sm" /> : <NpList size="sm" />}
        rightIcon={<ReactIcon as={MdArrowDropDown} size="sm" />}
        aria-label={title}
        {...props}
      >
        {title}
      </BlowfishButton>
    );
  },
);
