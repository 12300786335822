// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Spacer, SpacerProps } from '@chakra-ui/react';

interface BreadcrumbBarSpacerProps extends SpacerProps {
  collapse?: boolean;
}

export const BreadcrumbBarSpacer = forwardRef<BreadcrumbBarSpacerProps, 'div'>(
  ({ collapse, ...props }, ref) => {
    return (
      <Spacer ref={ref} display={collapse ? 'none' : undefined} {...props} />
    );
  },
);
