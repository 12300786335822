// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Flex, forwardRef, MenuItem, MenuItemProps } from '@chakra-ui/react';
import { MdCheck } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface Props extends Omit<MenuItemProps, 'icon'> {
  selected: boolean;
}

export const MenuItemSelectable = forwardRef<Props, 'div'>(
  ({ selected, children, ...props }, ref) => {
    return (
      <MenuItem ref={ref} {...props}>
        <Flex alignItems="center" gap={2}>
          <ReactIcon as={MdCheck} size="sm" opacity={selected ? 1 : 0} />
          {children}
        </Flex>
      </MenuItem>
    );
  },
);
