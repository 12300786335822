// Copyright © 2022 Niphtio, Inc.
// All Rights Reserved.

import {
  Flex,
  Link,
  Menu,
  MenuItem,
  MenuList,
  StackProps,
  Switch,
  useColorMode,
} from '@chakra-ui/react';
import NextLink from 'next/link';
import { FC } from 'react';
import { PageType } from '~/common/utilities/pageInfo';
import { BaseFancyProps, FancyStack } from '~/components/FancyComponents';
import { ConnectivityIconIndicator } from '~/components/NetworkIndicator';
import { QuickMenuButton } from '~/components/QuickMenuButton/QuickMenuButton';
import { useAppContext } from '~/containers/common/AppContext';
import { useFeatureContext } from '~/containers/common/FeatureContext/useFeatureContext';
import { SearchBar } from '~/containers/common/Search/SearchBar';
import { useLogout } from '~/hooks/useLogout';

interface Props extends BaseFancyProps, StackProps {}

export const HeaderAppNavBar: FC<Props> = ({ ...props }) => {
  const { getIsFeatureEnabled } = useFeatureContext();
  const { searchModal } = useAppContext();
  const { colorMode, toggleColorMode } = useColorMode();
  const { logout } = useLogout();

  return (
    <FancyStack direction="row" {...props}>
      <SearchBar
        flex="auto"
        visibility={searchModal.isOpen ? 'hidden' : 'visible'}
        mb={3}
        onClick={searchModal.onOpen}
      />
      <FancyStack spacing={2} direction="row">
        <ConnectivityIconIndicator />
        <Flex>
          <Menu>
            <QuickMenuButton variant="npGhostDust">Support</QuickMenuButton>
            <MenuList>
              <Link href={PageType.GuideCheckOffTodoItems} isExternal>
                <MenuItem>Help guides</MenuItem>
              </Link>
              <NextLink passHref href={PageType.ContactUs}>
                <MenuItem>Contact us</MenuItem>
              </NextLink>
            </MenuList>
          </Menu>
        </Flex>
        <Flex>
          <Menu placement="bottom-start">
            <QuickMenuButton variant="npGhostDust">My account</QuickMenuButton>
            <MenuList>
              <MenuItem
                onClick={toggleColorMode}
                justifyContent="space-between"
                closeOnSelect={false}
              >
                Dark mode
                <Switch
                  isChecked={colorMode === 'dark'}
                  pointerEvents={'none'}
                />
              </MenuItem>
              <NextLink passHref href={PageType.Settings}>
                <MenuItem>Settings</MenuItem>
              </NextLink>

              <NextLink passHref href={PageType.Import}>
                <MenuItem>Import</MenuItem>
              </NextLink>

              <MenuItem color="red" onClick={logout}>
                Log out
              </MenuItem>
            </MenuList>
          </Menu>
        </Flex>
      </FancyStack>
    </FancyStack>
  );
};
