// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';
import { mainMenuMobileHeight, tabPagePadding } from '~/common/uiTokens';

/**
 * A top level container for contents of a page linked to by a tab.
 * A tab refers to the items on a tabbar (in mobile) and the main navbar (on desktop).
 * - optmized for mobile and desktop.
 */
export const TabPage = forwardRef<FlexProps, 'div'>((props, ref) => (
  <Flex
    ref={ref}
    direction="column"
    flexGrow={1}
    overflowY="auto"
    minH="100vh"
    maxHeight="100%"
    p={tabPagePadding}
    {...props}
    pb={[
      mainMenuMobileHeight,
      mainMenuMobileHeight,
      /** Should not be covered by the mobile menu */
    ]}
  />
));
