// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Button,
  forwardRef,
  IconButton,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftAddon,
  InputProps,
  InputRightAddon,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { MdArrowBack } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons';

export interface BookmarkInputProps extends InputGroupProps {
  onDismiss: () => void;
  onSubmitBookmark: (bookmarkUrl: string) => Promise<any>;
  inputProps?: InputProps;
  focusInput?: boolean;
}

export const BookmarkInput = forwardRef<BookmarkInputProps, 'div'>(
  ({ onDismiss, onSubmitBookmark, inputProps, focusInput, ...props }, ref) => {
    const targetRef = useRef<HTMLInputElement>();
    const [bookmarkUrl, setBookmarkUrl] = useState('');

    const onSubmit = (e) => {
      e.preventDefault();
      onSubmitBookmark(bookmarkUrl)?.then((e) => {
        // on error do not reset bookmark url
        if (e) return;
        // on success reset bookmark url
        setBookmarkUrl('');
      });
    };

    useEffect(() => {
      // focus bookmark input if it's not collapsed
      if (!focusInput) return;
      targetRef.current?.focus();
    }, [focusInput, ref]);

    return (
      <InputGroup as="form" border="unset" {...props} onSubmit={onSubmit}>
        <InputLeftAddon p={0} border="unset">
          <IconButton
            variant="npGhost"
            borderRightRadius={0}
            icon={<ReactIcon as={MdArrowBack} />}
            onClick={onDismiss}
            aria-label="Back"
          />
        </InputLeftAddon>
        <Input
          ref={(node) => {
            targetRef.current = node;
            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          px={3}
          border="unset"
          boxShadow="unset"
          _hover={{
            _focus: { border: 'unset', boxShadow: 'unset' },
          }}
          _focus={{ border: 'unset', boxShadow: 'unset' }}
          _focusVisible={{ border: 'unset', boxShadow: 'unset' }}
          placeholder="Paste link here to add"
          aria-label="Paste link here to add"
          value={bookmarkUrl}
          {...inputProps}
          onChange={(e) => setBookmarkUrl(e.target.value)}
        />
        <InputRightAddon padding={0}>
          <Button type="submit" variant="npPrimary" borderLeftRadius={0}>
            Save
          </Button>
        </InputRightAddon>
      </InputGroup>
    );
  },
);
