// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  BoxProps,
  ButtonProps,
  forwardRef,
  MenuButton,
} from '@chakra-ui/react';
import { MdOutlineArrowDropDown } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons';
import { FancyButton, FancyProps } from '../FancyComponents';

export interface QuickMenuButtonProps
  extends ButtonProps,
    Omit<FancyProps, keyof BoxProps> {}

export const QuickMenuButton = forwardRef<QuickMenuButtonProps, 'button'>(
  ({ children, ...props }, ref) => {
    return (
      <MenuButton
        ref={ref}
        as={FancyButton}
        variant="npOutline"
        rightIcon={<ReactIcon as={MdOutlineArrowDropDown} />}
        {...props}
      >
        <Box textOverflow="ellipsis" overflow="hidden">
          {children}
        </Box>
      </MenuButton>
    );
  },
);
