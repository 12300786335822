// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import {
  Card,
  CardBody,
  CardProps,
  forwardRef,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

export const TileNote = forwardRef<CardProps, 'div'>(
  ({ children, ...props }, ref) => {
    const noteHover = useColorModeValue('npDust.500', 'npBurple.650');
    const noteBg = useColorModeValue('npDust.400', 'npBurple.600');
    const noteBorder = useColorModeValue('npDust.800', 'npBurple.500');
    return (
      <Card
        ref={ref}
        size="xs"
        border="1px solid"
        boxShadow="none"
        cursor="pointer"
        borderColor={noteBorder}
        bg={noteBg}
        _hover={{
          bg: noteHover,
          ...props._hover,
        }}
        {...props}
      >
        <CardBody>
          <Text
            fontSize="xs"
            fontWeight="medium"
            wordBreak="break-word"
            noOfLines={3}
            data-dd-privacy="mask"
          >
            {children}
          </Text>
        </CardBody>
      </Card>
    );
  },
);
