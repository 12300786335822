// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { MdClose } from 'react-icons/md';
import { NpTodoAdd } from '~/components/Icons/NpTodoAdd';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  tooltip?: Omit<TooltipProps, 'children'>;
  isTodo: boolean;
}

export const ToggleTodoIconButton = forwardRef<Props, 'div'>(
  ({ tooltip, isTodo, ...props }, ref) => {
    const label =
      (tooltip?.label ?? isTodo) ? 'Remove from To do' : 'Add to To do';
    return (
      <Tooltip label={label} placement="top">
        <IconButton
          ref={ref}
          variant="npGhost"
          icon={<ReactIcon as={isTodo ? MdClose : NpTodoAdd} />}
          aria-label={label.toString()}
          {...props}
        />
      </Tooltip>
    );
  },
);
