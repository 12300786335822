// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';
import { MdCheckCircle, MdCheckCircleOutline } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface TodoIconButtonProps extends Omit<IconButtonProps, 'aria-label'> {
  isDone: boolean;
  'aria-label'?: IconButtonProps['aria-label'];
}
export const TodoIconButton = forwardRef<TodoIconButtonProps, 'div'>(
  ({ isDone, ...props }, ref) => {
    return (
      <IconButton
        ref={ref}
        variant="ghost"
        aria-label={isDone ? 'Mark not done' : 'Mark done'}
        colorScheme={isDone ? 'green' : 'gray'}
        color={isDone ? 'green' : 'npGray.400'}
        isRound
        icon={
          <ReactIcon
            as={isDone ? MdCheckCircle : MdCheckCircleOutline}
            size="1.5rem"
          />
        }
        {...props}
      />
    );
  },
);
