// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Box, BoxProps, forwardRef } from '@chakra-ui/react';
import { onlyVisibleOnTileCardHoverClassName } from './constants';

export const TileRevealOnHover = forwardRef<BoxProps, 'div'>((props, ref) => {
  return (
    <Box
      ref={ref}
      className={onlyVisibleOnTileCardHoverClassName}
      {...props}
    ></Box>
  );
});
