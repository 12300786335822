// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { MenuButton } from '@chakra-ui/react';
import { FC } from 'react';
import { MdAdd } from 'react-icons/md';
import {
  BlowfishButton,
  BlowfishButtonProps,
} from '~/components/BlowfishButton';
import { ReactIcon } from '~/components/Icons/ReactIcon';
import { OnboardingAddBookmarkHint } from '~/containers/common/OnboardingHint/OnboardingAddBookmarkHint/OnboardingAddBookmarkHint';

export interface AddOptionsMenuButtonProps {
  buttonProps?: Omit<BlowfishButtonProps, 'aria-label'>;
}

export const AddOptionsMenuButton: FC<AddOptionsMenuButtonProps> = ({
  buttonProps,
}) => {
  return (
    <OnboardingAddBookmarkHint placement="bottom-start">
      <BlowfishButton
        as={MenuButton}
        variant={'npGhost'}
        size="sm"
        leftIcon={<ReactIcon as={MdAdd} size="sm" />}
        aria-label="Add item"
        {...buttonProps}
        onClick={(e) => {
          buttonProps?.onClick?.(e);
        }}
      >
        Add
      </BlowfishButton>
    </OnboardingAddBookmarkHint>
  );
};
