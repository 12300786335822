// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, Link, MenuButton, useDisclosure } from '@chakra-ui/react';
import first from 'lodash/first';
import isNil from 'lodash/isNil';
import NextLink from 'next/link';
import { FC, SyntheticEvent } from 'react';
import { TodoItem } from '~/common/gql';
import { useListSectionsQuery } from '~/common/gql/section.generated';
import { getItemContent } from '~/common/utilities/item-utils/getItemContent';
import { getIsTodo, getIsTodoDone } from '~/common/utilities/item-utils/item';
import { getBaseUrl } from '~/common/utilities/url-utils/getBaseUrl';
import { getUrlAbbreviation } from '~/common/utilities/url-utils/getUrlAbbreviation';
import { getUrlColor } from '~/common/utilities/url-utils/getUrlColor';
import { ActionType } from '~/common/utilities/usage-event/actionType';
import { IfWrap } from '~/components/IfWrap/IfWrap';
import { TagBlock } from '~/components/TagBlock/TagBlock';
import { useTagBlock } from '~/components/TagBlock/useTagBlock';
import { getEnhancedCollections } from '~/containers/common/Collection/getEnhancedCollection';
import { ModalCollectionPicker } from '~/containers/common/Collection/ModalCollectionPicker/ModalCollectionPicker';
import { useModalCollectionPickerForItem } from '~/containers/common/Collection/ModalCollectionPicker/useModalCollectionPickerForItem';
import { ItemMenu } from '~/containers/common/Item/ItemMenu/ItemMenu';
import { ModalItemDelete } from '~/containers/common/Item/ModalItemDelete/ModalItemDelete';
import { ModalItemNoteDelete } from '~/containers/common/Item/ModalItemNoteDelete/ModalItemNoteDelete';
import { ModalItemNoteEdit } from '~/containers/common/Item/ModalItemNoteEdit/ModalItemNoteEdit';
import { ModalItemTitleRename } from '~/containers/common/Item/ModalItemTitleRename/ModalItemTitleRename';
import { MoreIconButton } from '~/containers/common/Item/MoreIconButton';
import { OpenOriginalIconButton } from '~/containers/common/Item/OpenOriginalIconButton';
import { TodoIconButton } from '~/containers/common/Item/TodoIconButton';
import { TPrivateItemCard } from '~/containers/common/Item/TPrivateItemCard';
import { useItemTags } from '~/containers/common/Item/useItemTags';
import { OnboardingCardTodoMarkDoneHint } from '~/containers/common/OnboardingHint/OnboardingCardTodoMarkDoneHint';
import { getEnhancedTodoItem } from '~/containers/common/Todo/getEnhancedTodoItem';
import { TodoUpdatedToast } from '~/containers/common/Todo/TodoUpdatedToast';
import { WideCard } from '~/containers/common/WideCard/WideCard';
import { WideCardBody } from '~/containers/common/WideCard/WideCardBody';
import { getItemPath } from '~/containers/pages/ReaderPage/helpers';
import { useCollectionsQueryHelper } from '~/hooks/useCollectionsQueryHelper';
import { useDisclosureWithTracking } from '~/hooks/useDisclosureWithTracking';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useUsageEventCreateMutationHelper } from '~/hooks/useUsageEventCreateMutationHelper';
import { onlyVisibleOnWideCardHoverClassName } from '../WideCard/constants';
import { WideCardButtonsCell } from '../WideCard/WideCardButtonsCell';
import { WideCardContentCell } from '../WideCard/WideCardContentCell';
import { WideCardMetadataCell } from '../WideCard/WideCardMetadataCell';
import { ItemWideCardNoNote, WideCardNoteText } from '../WideCard/WideCardNote';
import { WideCardFlexNote } from '../WideCard/WideCardNote/WideCardFlexNote';
import { WideCardThumbnailCell } from '../WideCard/WideCardThumbnailCell';
import { WideTitle } from '../WideCard/WideTitle';
import { WideUrl } from '../WideCard/WideUrl';
import { ToggleTodoIconButton } from './ToggleTodoIconButton';

export const ItemWideCard: FC<TPrivateItemCard> = ({
  item,
  showCardTodoMarkDoneHint,
  showCardManageTagsHint,
}) => {
  const todo = first(item.todo);
  const isDone = getIsTodoDone(item);
  const itemContent = getItemContent(item);
  const baseUrl = getBaseUrl(itemContent.url);
  const fallbackHeading = getUrlAbbreviation(itemContent.url);
  const fallbackBg = getUrlColor(itemContent.url);
  const itemPath = getItemPath({ id: item.id });

  const { renderToastWith } = useToastHelper();
  const sectionsQuery = useListSectionsQuery();
  const { userCollections } = useCollectionsQueryHelper();
  const { logUsageEventWithAttributes } = useUsageEventCreateMutationHelper();
  const modalItemTitleRename = useDisclosure();
  const modalItemNoteEdit = useDisclosureWithTracking({
    openAction: ActionType.OPEN_NOTE_EDITOR,
  });
  const modalItemNoteDelete = useDisclosure();
  const modalItemDelete = useDisclosure();

  const enhancedTodo = getEnhancedTodoItem(item.todo);
  const enhancedCollections = getEnhancedCollections({
    all: userCollections,
    selected: item.collection,
  });

  const tagBlock = useTagBlock({
    item: {
      id: item.id,
    },
    tags: enhancedCollections.filter((it) => it.selected),
    showCardManageTagsHint,
  });

  const { createTodo, removeTodo } = useItemTags({
    item: { id: item.id },
  });

  const tagPickerPopupProps = useModalCollectionPickerForItem({
    sections: sectionsQuery.data?.listSections ?? [],
    item: {
      id: item.id,
    },
    enhancedCollections: enhancedCollections,
  });

  const { setTodoDone: _setTodoDone } = useItemTags({
    item: { id: item.id },
  });

  const setTodoDone = (todoId: TodoItem['id'], isDone: boolean) => {
    renderToastWith({
      message: (
        <TodoUpdatedToast
          isDone={isDone}
          onClick={() => {
            _setTodoDone(todoId, !isDone);
          }}
        />
      ),
    })();
    _setTodoDone(todoId, isDone);
  };

  const onClickItem = (event: SyntheticEvent) => {
    if (!isNil(item.note)) {
      // only log event if item has a note
      logUsageEventWithAttributes(
        event,
        {
          actionType: ActionType.OPEN_ITEM_WITH_NOTE,
        },
        ['text'],
      );
    }
    if (getIsTodo(item)) {
      // only log event if item has a to do
      logUsageEventWithAttributes(
        event,
        {
          actionType: ActionType.OPEN_ITEM_WITH_TODO,
        },
        ['text'],
      );
    }
  };

  const onClickItemURL = (event: SyntheticEvent) => {
    logUsageEventWithAttributes(event, {
      actionType: ActionType.CLICK_ITEM_URL,
    });
  };

  return (
    <>
      {item && (
        <ModalItemTitleRename
          item={item}
          isOpen={modalItemTitleRename.isOpen}
          onClose={modalItemTitleRename.onClose}
        />
      )}
      {item && (
        <ModalItemNoteEdit
          item={item}
          isOpen={modalItemNoteEdit.isOpen}
          onClose={modalItemNoteEdit.onClose}
          onDelete={modalItemNoteDelete.onOpen}
        />
      )}
      {item && (
        <ModalItemNoteDelete
          item={item}
          isOpen={modalItemNoteDelete.isOpen}
          onClose={modalItemNoteDelete.onClose}
        />
      )}
      {item && (
        <ModalItemDelete
          item={item}
          isOpen={modalItemDelete.isOpen}
          onClose={modalItemDelete.onClose}
        />
      )}
      {item && <ModalCollectionPicker {...tagPickerPopupProps} />}
      <WideCard data-wide-card>
        <WideCardBody gap={2}>
          <Flex
            gap={2}
            flex="1 1 auto"
            direction={['column', 'column', 'column', 'row']}
          >
            <NextLink href={itemPath} passHref legacyBehavior>
              <WideCardThumbnailCell
                display={['none', 'none', 'none', 'flex']}
                title={itemContent.title}
                imageSrc={itemContent.imageSrc}
                fallbackHeading={fallbackHeading}
                fallbackBg={fallbackBg}
                onClick={onClickItem}
              />
            </NextLink>
            <NextLink href={itemPath} passHref legacyBehavior>
              <WideCardContentCell onClick={onClickItem}>
                <WideTitle>{itemContent.title}</WideTitle>
                <WideUrl>{baseUrl}</WideUrl>
                {itemContent.note && (
                  <WideCardNoteText
                    display={[
                      '-webkit-box',
                      '-webkit-box',
                      '-webkit-box',
                      'none',
                    ]}
                  >
                    {itemContent.note}
                  </WideCardNoteText>
                )}
              </WideCardContentCell>
            </NextLink>
            <WideCardMetadataCell>
              <TagBlock
                active={tagPickerPopupProps.popup.isOpen}
                onClickTagAdd={tagPickerPopupProps.popup.onOpen}
                maxTags={3}
                {...tagBlock}
              />
              <WideCardFlexNote
                display={['none', 'none', 'none', 'flex']}
                className={
                  itemContent.note
                    ? undefined
                    : onlyVisibleOnWideCardHoverClassName
                }
                onClick={modalItemNoteEdit.onOpen}
              >
                {!itemContent.note && <ItemWideCardNoNote />}
                {itemContent.note && (
                  <WideCardNoteText fontWeight="medium">
                    {itemContent.note}
                  </WideCardNoteText>
                )}
              </WideCardFlexNote>
            </WideCardMetadataCell>
          </Flex>
          <WideCardButtonsCell>
            <Link
              className={onlyVisibleOnWideCardHoverClassName}
              href={item.url}
              onClick={onClickItemURL}
              isExternal
            >
              <OpenOriginalIconButton />
            </Link>
            {enhancedTodo.selected && (
              <IfWrap
                condition={showCardTodoMarkDoneHint}
                wrapWith={(children) => (
                  <OnboardingCardTodoMarkDoneHint>
                    {children}
                  </OnboardingCardTodoMarkDoneHint>
                )}
              >
                <TodoIconButton
                  isDone={enhancedTodo.isDone}
                  onClick={() => setTodoDone(todo.id, !isDone)}
                />
              </IfWrap>
            )}
            {!enhancedTodo.selected && (
              <ToggleTodoIconButton
                className={onlyVisibleOnWideCardHoverClassName}
                isTodo={enhancedTodo.selected}
                onClick={
                  enhancedTodo.selected
                    ? () => removeTodo(enhancedTodo.id)
                    : createTodo
                }
              />
            )}
            <ItemMenu
              item={item}
              onEditItemCollections={tagPickerPopupProps.popup.onOpen}
              onRenameTitle={modalItemTitleRename.onOpen}
              onEditNote={modalItemNoteEdit.onOpen}
              onDeleteNote={modalItemNoteDelete.onOpen}
              onDeleteItem={modalItemDelete.onOpen}
            >
              <MenuButton as={MoreIconButton} aria-label="Open item menu" />
            </ItemMenu>
          </WideCardButtonsCell>
        </WideCardBody>
      </WideCard>
    </>
  );
};
