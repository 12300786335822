// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Kbd, Spacer, Text } from '@chakra-ui/react';
import { MdOutlineSearch } from 'react-icons/md';
import { FancyButton, FancyProps } from '~/components/FancyComponents';
import { ReactIcon } from '~/components/Icons';
import { useSearchContext } from '~/containers/common/Search/SearchContext/useSearchContext';

export const SearchBar = forwardRef<FancyProps, 'button'>(
  ({ children, ...props }, ref) => {
    const { searchTerm } = useSearchContext();

    return (
      <FancyButton
        ref={ref}
        variant="npOutline"
        leftIcon={<ReactIcon as={MdOutlineSearch} />}
        justifyContent="flex-start"
        fontWeight="normal"
        noOfLines={1}
        desktopOnly
        {...props}
      >
        <Text noOfLines={1}>
          {!children &&
            (searchTerm ? searchTerm : 'Search for items and collections...')}
        </Text>
        {children}
        <Spacer />
        <Kbd variant="npBranded">/</Kbd>
      </FancyButton>
    );
  },
);
