// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef } from '@chakra-ui/react';
import { TagBadge, TagBadgeLabel } from '@niphtio/np-theme';
import isEmpty from 'lodash/isEmpty';
import { Collection } from '~/common/gql';
import { TagBase } from '~/components/TagBase';
import { TagsWrap, TagsWrapProps } from '~/components/TagsWrap/TagsWrap';
import { TagBlockItemProps } from './TagBlockItemProps';

export interface TagBlockProps extends TagsWrapProps {
  tags: TagBlockItemProps[];
  maxTags?: number;
  onCloseTag: (tag: Pick<Collection, 'id'>) => void;
}

/** Convenince component */
export const TagBlock = forwardRef<TagBlockProps, 'div'>(
  ({ tags, maxTags, onCloseTag, ...props }, ref) => {
    const visibleTags = maxTags ? tags.slice(0, maxTags) : tags;
    const overflowTags = maxTags ? tags.slice(maxTags) : [];
    const isTagOverflow = !isEmpty(overflowTags);
    return (
      <TagsWrap ref={ref} {...props}>
        {visibleTags.map((it) => (
          <TagBase key={it.id} onClose={() => onCloseTag(it)}>
            {it.title}
          </TagBase>
        ))}
        {isTagOverflow && (
          <TagBadge>
            <TagBadgeLabel>+{overflowTags.length}</TagBadgeLabel>
          </TagBadge>
        )}
      </TagsWrap>
    );
  },
);
