// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, IconButton, IconButtonProps } from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface MoreIconButtonProps extends IconButtonProps {}

export const MoreIconButton = forwardRef<MoreIconButtonProps, 'div'>(
  (props, ref) => {
    return (
      <IconButton
        ref={ref}
        variant="npGhost"
        icon={<ReactIcon as={MdMoreVert} />}
        {...props}
      />
    );
  },
);
