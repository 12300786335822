// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  Center,
  CenterProps,
  forwardRef,
  Heading,
  Image,
  ImageProps,
  useBoolean,
} from '@chakra-ui/react';

interface Props extends Omit<ImageProps, 'src'> {
  title: string;
  imageSrc: string;
  fallbackHeading: string;
  fallbackBg: CenterProps['bg'];
}

export const TileImage = forwardRef<Props, 'div'>(
  ({ title, imageSrc, fallbackHeading, fallbackBg, ...props }, ref) => {
    const [imageError, { on: errorOn }] = useBoolean(false);

    return (
      <>
        {imageSrc && !imageError && (
          <Image
            width="full"
            height="132px"
            fit="cover"
            alt={title}
            src={imageSrc}
            onError={errorOn}
            data-dd-privacy="mask"
          />
        )}
        {(!imageSrc || imageError) && (
          <Center width="full" height="132px" bg={fallbackBg}>
            <Heading color="white" data-dd-privacy="mask">
              {fallbackHeading}
            </Heading>
          </Center>
        )}
      </>
    );
  },
);
