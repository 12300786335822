// Copyright © 2021 Niphtio, Inc.

import isEmpty from 'lodash/isEmpty';
import { useState } from 'react';
import { copy } from '~/common/copy/copy';
import { ItemCreateMutationVariables } from '~/common/gql';
import { isUrlHttpOrHttps } from '~/common/utilities/url-utils/isUrlHttpOrHttps';
import { useItemByUrlQueryHelper } from '~/hooks/useItemByUrlQueryHelper';
import { useItemCreateQueryHelper } from '~/hooks/useItemCreateQueryHelper';
import { useToastHelper } from '~/hooks/useToastHelper';
import { useUserQueryHelper } from '~/hooks/useUserQueryHelper';
import { BookmarkInputProps } from './BookmarkInput';

interface Props {
  bookmarkType: Pick<
    ItemCreateMutationVariables['input'],
    'isTodo' | 'collectionIds'
  >;
  onSuccess: () => Promise<any>;
}

interface ReturnType {
  active: boolean;
  focused: boolean;
  setActive: (value: boolean) => void;
  props: BookmarkInputProps;
}

export const useBookmarkInput = ({
  bookmarkType,
  onSuccess,
}: Props): ReturnType => {
  const { renderToastWith } = useToastHelper();
  const [createItemMutation] = useItemCreateQueryHelper();
  const [getItemByUrl] = useItemByUrlQueryHelper();

  const { userSettings } = useUserQueryHelper();
  const [active, setActive] = useState(false);
  const [focused, setFocused] = useState(false);

  const onSubmitBookmark = async (bookmarkUrl) => {
    if (!isUrlHttpOrHttps(bookmarkUrl)) return;
    const urlQuery = await getItemByUrl(bookmarkUrl);
    const isNewItem = isEmpty(urlQuery.data?.itemByUrl);
    const isNewAndTodoIsDefault = isNewItem && userSettings.isTodoDefault;
    const isNewAndDefaultCollections = isNewItem
      ? userSettings.defaultCollections.map((it) => it.collectionId)
      : [];
    const isInTag = bookmarkType.collectionIds ?? [];
    const isTodo = bookmarkType.isTodo || isNewAndTodoIsDefault;
    const collectionIds = [...isNewAndDefaultCollections, ...isInTag];

    return createItemMutation(
      {
        input: {
          url: bookmarkUrl,
          isTodo,
          collectionIds,
        },
      },
      {
        onSuccess: renderToastWith({
          message: copy.createItemSuccess,
          then: () => {
            setActive(false);
            ``;
            return onSuccess();
          },
        }),
        onError: (e) => {
          const errors = e?.graphQLErrors
            ?.map((it) => it.exception?.detailMessage ?? it.message)
            .join('\n');

          renderToastWith({
            message: errors ?? copy.createItemError,
          })();
        },
      },
    );
  };

  const onDismiss = () => {
    setActive(false);
  };

  return {
    active,
    setActive,
    focused,
    props: {
      inputProps: {
        onFocus: () => setFocused(true),
        onBlur: () => setFocused(false),
      },
      onSubmitBookmark,
      onDismiss,
      focusInput: active,
    },
  };
};
