// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { PopoverProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { HintPopover } from '~/components/HintPopover/HintPopover';
import { useOnboardingAddBookmarkHint } from './useOnboardingAddBookmarkHint';

interface Props extends Omit<PopoverProps, 'children'>, PropsWithChildren {}

export const OnboardingAddBookmarkHint: FC<Props> = ({
  children,
  ...props
}) => {
  const tip = useOnboardingAddBookmarkHint();
  if (tip.isOpen) {
    return (
      <HintPopover
        renderInPortal={false}
        hintText={'Add a web page to your library.'}
        dismissText={'Maybe later'}
        isOpen={tip.isOpen}
        onClose={tip.onClose}
        {...props}
      >
        {children}
      </HintPopover>
    );
  }
  return <>{children}</>;
};
