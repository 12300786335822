// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  Link,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuListProps,
} from '@chakra-ui/react';
import { chromeExtUrl, firefoxExtUrl } from '~/common/links';
import { PageType } from '~/common/utilities/pageInfo';
import { useModalCollectionCreateContext } from '~/containers/common/Collection/ModalCollectionCreate/useModalCollectionCreateContext';

export interface AddOptionsMenuListProps
  extends Omit<MenuListProps, 'children'> {
  onAddBookmark: () => void;
}

export const AddOptionsMenuList = forwardRef<AddOptionsMenuListProps, 'div'>(
  ({ onAddBookmark, ...props }, ref) => {
    const modalCollectionCreate = useModalCollectionCreateContext();
    return (
      <MenuList ref={ref} {...props}>
        <MenuGroup title="Add a web page">
          <MenuItem onClick={onAddBookmark}>Paste URL manually</MenuItem>
          <Link href={PageType.Import}>
            <MenuItem>Import</MenuItem>
          </Link>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Extensions">
          <Link href={chromeExtUrl} target="_blank">
            <MenuItem>Chrome extension</MenuItem>
          </Link>
          <Link href={firefoxExtUrl} target="_blank">
            <MenuItem>Firefox extension</MenuItem>
          </Link>
          <Link href={PageType.GuideHowToUseTheBookmarklet} target="_blank">
            <MenuItem>Bookmarklet</MenuItem>
          </Link>
        </MenuGroup>
        <MenuDivider />
        <MenuGroup title="Other">
          <MenuItem onClick={modalCollectionCreate.onOpen}>
            Create a collection
          </MenuItem>
        </MenuGroup>
      </MenuList>
    );
  },
);
