// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { ReactIcon, ReactIconProps } from './ReactIcon';

export const NpTodoAdd = (props: ReactIconProps) => (
  <ReactIcon viewBox="0 0 24 24" {...props}>
    <path
      id="Vector"
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.3452 5.1367C11.5736 5.27451 11.7836 5.40121 12 5.5C12.2164 5.40121 12.4264 5.27451 12.6548 5.1367C13.4774 4.6404 14.5388 4 17 4C20.1447 4 23 5.67893 23 5.67893V12.5H21V6.83285C20.182 6.49696 18.8919 6 17 6C15.1081 6 13 7 13 7V17.0234C13.3218 16.9368 13.6668 16.8111 14.0342 16.6773L14.0342 16.6773C14.3407 16.5656 14.6628 16.4483 15 16.3433V18.2241C14.0751 18.4482 13.4172 18.8003 12.8773 19.0892C12.553 19.2627 12.2713 19.4135 12 19.5C11.7287 19.4135 11.447 19.2627 11.1227 19.0892C10.2568 18.6259 9.08726 18 7 18C4.13106 18 1 19.0062 1 19.0062V5.67893C1 5.67893 3.85534 4 7 4C9.46122 4 10.5226 4.6404 11.3452 5.1367ZM11 7V17.0234C10.6782 16.9368 10.3332 16.8111 9.96575 16.6773C9.09848 16.3614 8.10642 16 7 16C5.38136 16 3.5 16.5 3 16.698V6.83285C3.81802 6.49696 5.10808 6 7 6C8.89192 6 11 7 11 7ZM21 14.5V16.5H23V18.5H21V20.5H19V18.5H17V16.5H19V14.5H21Z"
      fill="currentColor"
    />
  </ReactIcon>
);
