// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import {
  Box,
  Button,
  CloseButton,
  forwardRef,
  Heading,
  Link,
  Stack,
  Text,
} from '@chakra-ui/react';
import { PWAInstall } from '~/common/pwa';
import { PageType } from '~/common/utilities/pageInfo';
import {
  HintCollapse,
  HintCollapseProps,
} from '~/components/HintCollapse/HintCollapse';

interface Props extends HintCollapseProps {
  onClose: () => void;
}

export const InstallPWAHintAd = forwardRef<Props, 'div'>(
  ({ onClose, ...props }, ref) => {
    return (
      <HintCollapse ref={ref} {...props}>
        <Stack spacing={3}>
          <CloseButton ml="auto" onClick={onClose} />
          <Heading size="md">
            Save items faster with our web app for Android
          </Heading>
          <Text>
            If you use Chrome on Android, install our Progressive Web App to
            instantly save links directly to Niphtio.
          </Text>
          <Box>
            <Button mr={4} variant="npPrimary" onClick={PWAInstall}>
              Install the app
            </Button>
          </Box>
          <Link variant="npAccent" href={PageType.Pwa}>
            What’s a Progressive Web App (PWA)?
          </Link>
        </Stack>
      </HintCollapse>
    );
  },
);
