// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { PopoverProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { HintPopover } from '~/components/HintPopover/HintPopover';
import { useOnboardingHint } from './useOnboardingHint';

interface Props extends Omit<PopoverProps, 'children'>, PropsWithChildren {}

export const OnboardingCardTodoMarkDoneHint: FC<Props> = ({
  children,
  ...props
}) => {
  const tip = useOnboardingHint('hideCardTodoMarkDoneHint');
  return (
    <HintPopover
      renderInPortal={false}
      hintText={
        'You can check off a to do item here when you are finished reading it.'
      }
      dismissText={'Maybe later'}
      isOpen={tip.isOpen}
      onClose={tip.onClose}
      {...props}
    >
      {children}
    </HintPopover>
  );
};
