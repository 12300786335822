// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import {
  forwardRef,
  IconButton,
  IconButtonProps,
  Tooltip,
  TooltipProps,
} from '@chakra-ui/react';
import { MdOpenInNew } from 'react-icons/md';
import { ReactIcon } from '~/components/Icons/ReactIcon';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
  tooltip?: Omit<TooltipProps, 'children'>;
}

export const OpenOriginalIconButton = forwardRef<Props, 'div'>(
  ({ tooltip, ...props }, ref) => {
    const label = tooltip?.label ?? 'Open original';
    return (
      <Tooltip label={label} placement="top">
        <IconButton
          ref={ref}
          variant="npGhost"
          icon={<ReactIcon as={MdOpenInNew} />}
          aria-label={label.toString()}
          {...props}
        />
      </Tooltip>
    );
  },
);
