// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { PopoverProps } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { HintPopover } from '~/components/HintPopover/HintPopover';
import { useOnboardingHint } from './useOnboardingHint';

interface Props extends Omit<PopoverProps, 'children'>, PropsWithChildren {}

export const OnboardingCardManageTagsHint: FC<Props> = ({
  children,
  ...props
}) => {
  const tip = useOnboardingHint('hideCardManageTagsHint');
  return (
    <HintPopover
      renderInPortal={false}
      hintText={'Add collections to organize your items.'}
      dismissText={'Maybe later'}
      isOpen={tip.isOpen}
      onClose={tip.onClose}
      {...props}
    >
      {children}
    </HintPopover>
  );
};
