// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface BreadcrumbBarProps extends FlexProps {}

export const BreadcrumbBar = forwardRef<BreadcrumbBarProps, 'div'>(
  (props, ref) => {
    return (
      <Flex
        ref={ref}
        gap={[1, 1, 2]}
        minH="40px"
        alignItems="center"
        {...props}
      />
    );
  },
);
