// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { FlexProps, forwardRef } from '@chakra-ui/react';
import { BaseFancyProps, FancyFlex } from '~/components/FancyComponents';

interface BreadcrumbBarItemProps extends FlexProps, BaseFancyProps {
  collapse?: boolean;
}

export const BreadcrumbBarItem = forwardRef<BreadcrumbBarItemProps, 'div'>(
  ({ collapse, ...props }, ref) => {
    return (
      <FancyFlex ref={ref} display={collapse ? 'none' : undefined} {...props} />
    );
  },
);
