// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Text, TextProps } from '@chakra-ui/react';

export const TileDescription = forwardRef<TextProps, 'div'>((props, ref) => {
  return (
    <Text
      ref={ref}
      fontSize="xs"
      fontWeight="normal"
      wordBreak="break-word"
      noOfLines={3}
      data-dd-privacy="mask"
      {...props}
    />
  );
});
