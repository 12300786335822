// Copyright © 2021 Niphtio, Inc.

import { useState } from 'react';

interface Props {
  showHint: (hovering: boolean) => boolean;
}

export const useHintCollapse = ({ showHint }: Props) => {
  const [isHoveringHint, setIsHoveringHint] = useState(false);

  return {
    expand: showHint(isHoveringHint),
    setIsHoveringHint,
  };
};
