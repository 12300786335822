// Copyright © 2021 Niphtio, Inc.

import { useEffect, useState } from 'react';
import { NIPHTIO_CHROME_EXTENSION_ID } from '~/common/config/runtime';

interface Props {
  assume: boolean;
}

export const useIsChromeExtensionInstalled = ({
  // for parity on client-server
  assume = true, // assume extension is installed
}: Props) => {
  const [installed, setInstalled] = useState(assume);

  useEffect(() => {
    if (!global.chrome?.runtime?.sendMessage) return;
    global.chrome?.runtime?.sendMessage(
      NIPHTIO_CHROME_EXTENSION_ID,
      { query: 'isExtensionInstalled' },
      (result) => {
        setInstalled(!!result?.data);
      },
    );
  }, []);

  return {
    installed,
  };
};
