// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { useEffect, useState } from 'react';
import { isAndroidOs } from '~/common/utilities/browser-utils';
import { LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY } from '~/common/utilities/local-store/localStorageKeys';
import { localStoreJson } from '~/common/utilities/local-store/localStoreJson';
import { useHintCollapse } from '~/components/HintCollapse/useHintCollapse';
import { useIsChromeExtensionInstalled } from './useIsChromeExtensionInstalled';

interface Props {
  isAddingBookmark: boolean;
}

export const useInputHintAd = ({ isAddingBookmark }: Props) => {
  const { installed: isExtensionInstalled } = useIsChromeExtensionInstalled({
    assume: true,
  });

  const isAndroid = isAndroidOs();

  const [pwaAdDismissed, setPwaAdDismissed] = useState(true);

  const extAdProps = useHintCollapse({
    showHint: (hovering) =>
      (!isExtensionInstalled && isAddingBookmark) || hovering,
  });

  const pwaAdProps = useHintCollapse({
    showHint: (hovering) =>
      !pwaAdDismissed && (hovering || (isAndroid && isAddingBookmark)),
  });

  const closePwaAd = () => {
    setPwaAdDismissed(true);
    localStoreJson.setItem(LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY, true);
  };

  useEffect(() => {
    setPwaAdDismissed(
      localStoreJson.getItem(LS_USD_INSTALL_PWA_HINT_DISMISSED_KEY) === true,
    );
  }, []);

  return {
    extAdProps: {
      ...extAdProps,
    },
    pwaAdProps: {
      ...pwaAdProps,
      onClose: closePwaAd,
    },
  };
};
