// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { GridProps } from '@chakra-ui/react';
import { FC } from 'react';
import { LayoutType } from '~/common/gql';
import { ListLayout } from './ListLayout';
import { TileLayout } from './TileLayout';

interface Props extends GridProps {
  layout: LayoutType;
}

export const CollectionLayout: FC<Props> = ({ layout, ...props }) => {
  if (layout === LayoutType.List) {
    return <ListLayout {...props} />;
  }
  return <TileLayout {...props} />;
};
