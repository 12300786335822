// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Heading, Stack, StackProps, Text } from '@chakra-ui/react';
import React from 'react';

interface OopsMessageProps {
  image: React.ReactElement;
  heading?: string;
  description?: string;
}
export const OopsMessage = forwardRef<StackProps & OopsMessageProps, 'div'>(
  ({ image, heading, description, children, ...props }, ref) => (
    <Stack ref={ref} alignItems="center" spacing={1} {...props}>
      {image}
      {heading && <Heading size="md">{heading}</Heading>}
      {description && <Text textAlign="center">{description}</Text>}
      {children}
    </Stack>
  ),
);
