// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { Flex, FlexProps, forwardRef } from '@chakra-ui/react';

interface Props extends FlexProps {}

export const WideCardButtonsCell = forwardRef<Props, 'div'>((props, ref) => {
  return <Flex ref={ref} flex="0 0 content" {...props} />;
});
