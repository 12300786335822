// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, TagProps } from '@chakra-ui/react';
import {
  TagBadge,
  TagBadgeCloseButton,
  TagBadgeLabel,
} from '@niphtio/np-theme';

export interface TagBaseProps extends TagProps {
  onClose: () => void;
}

export const TagBase = forwardRef<TagBaseProps, 'span'>(
  ({ children, onClose, ...props }, ref) => {
    return (
      <TagBadge ref={ref} className="tag-badge" {...props}>
        <TagBadgeLabel>{children}</TagBadgeLabel>
        <TagBadgeCloseButton
          display="none"
          height="full"
          sx={{
            '.tag-badge:hover > &': {
              display: 'flex',
            },
          }}
          onClick={(e) => {
            e.stopPropagation();
            onClose();
          }}
        />
      </TagBadge>
    );
  },
);
