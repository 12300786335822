// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { FC } from 'react';
import { LayoutType } from '~/common/gql';
import { ItemTileCard } from '~/containers/common/Item/ItemTileCard';
import { ItemWideCard } from '~/containers/common/Item/ItemWideCard';
import { TPrivateItemCard } from '~/containers/common/Item/TPrivateItemCard';

interface Props extends TPrivateItemCard {
  layout: LayoutType;
}

export const PrivateCollectionCardLayout: FC<Props> = ({
  layout,
  ...props
}) => {
  if (layout === LayoutType.List) {
    return <ItemWideCard {...props} />;
  }
  return <ItemTileCard {...props} />;
};
