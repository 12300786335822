// Copyright © 2023 Niphtio, Inc.
// All Rights Reserved.

import { LayoutType } from '~/common/gql';
import {
  useUpdatePreferenceMutation,
  useUserPreferenceQuery,
} from '~/common/gql/user.generated';

export interface UseCollectionLayoutReturnType {
  layout: LayoutType;
  setLayout: (collectionLayout: LayoutType) => void;
}

export const useCollectionLayout = (): UseCollectionLayoutReturnType => {
  const userPreferenceQuery = useUserPreferenceQuery();
  const [updatePreferenceMutation] = useUpdatePreferenceMutation();
  const layout =
    userPreferenceQuery.data?.userPreference?.collectionLayout ??
    LayoutType.Tile; // Uses Tile layout by default

  const setLayout = (collectionLayout: LayoutType) => {
    updatePreferenceMutation({
      variables: {
        input: {
          collectionLayout,
        },
      },
    });
  };

  return {
    layout,
    setLayout,
  };
};
