// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Grid, GridProps } from '@chakra-ui/react';
import { FC } from 'react';
import { tabPagePadding } from '~/common/uiTokens';

export const ListLayout: FC<GridProps> = (props) => {
  return (
    <Grid
      templateColumns={['repeat(1, 1fr)']}
      gap={'1px'}
      borderRadius={['none', 'none', 'md']}
      mx={[`-${tabPagePadding}`, `-${tabPagePadding}`, 0]}
      {...props}
    />
  );
};
