// Copyright © 2021 Niphtio, Inc.
// All Rights Reserved.

import { forwardRef, Wrap, WrapProps } from '@chakra-ui/react';
import { TagBadge } from '@niphtio/np-theme';
import { IfWrap } from '~/components/IfWrap/IfWrap';
import { OnboardingCardManageTagsHint } from '~/containers/common/OnboardingHint/OnboardingCardManageTagsHint';
import { NpAdd } from '../Icons/NpAdd';

export interface TagsWrapProps extends WrapProps {
  active: boolean;
  showCardManageTagsHint?: boolean;
  onClickTagAdd?: VoidFunction;
}

export const TagsWrap = forwardRef<TagsWrapProps, 'div'>(
  (
    { active, onClickTagAdd, showCardManageTagsHint, children, ...props },
    ref,
  ) => {
    const { _active = {} } = props;
    const { borderColor: activeBorderColor = 'npBlue.500' } = _active;

    return (
      <Wrap
        ref={ref}
        spacing={0.5}
        wrap="wrap"
        margin={'-2px'}
        borderRadius="md"
        borderWidth="2px"
        borderStyle="solid"
        borderColor={active ? (activeBorderColor as any) : 'transparent'}
        {...props}
      >
        {children}
        {onClickTagAdd && (
          <IfWrap
            condition={showCardManageTagsHint}
            wrapWith={(children) => (
              <OnboardingCardManageTagsHint>
                {children}
              </OnboardingCardManageTagsHint>
            )}
          >
            <TagBadge
              cursor="pointer"
              data-tag-badge-add
              px={1}
              onClick={onClickTagAdd}
            >
              <NpAdd boxSize="1rem" />
            </TagBadge>
          </IfWrap>
        )}
      </Wrap>
    );
  },
);
