// Copyright © 2024 Niphtio, Inc.
// All Rights Reserved.

import { Grid, GridProps } from '@chakra-ui/react';
import { FC } from 'react';

export const TileLayout: FC<GridProps> = (props) => {
  return (
    <Grid
      templateColumns={[
        'repeat(1, 1fr)',
        'repeat(1, 1fr)',
        'repeat(auto-fill, minmax(20rem, 1fr))',
      ]}
      gap={2}
      {...props}
    />
  );
};
